@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    font-family: "Urbanist";
}

@layer components {
    .navbar-active #hamburger div:first-child {
        @apply translate-y-1.5 rotate-45;
    }
    .navbar-active #hamburger div:last-child {
        @apply -translate-y-1 -rotate-45;
    }
    .navbar-active #layer {
        @apply origin-top scale-y-100;
    }
    .navbar-active #navlinks {
        @apply visible block scale-100 opacity-100 lg:flex lg:translate-y-0;
    }
    .card {
        @apply bg-white drop-shadow-md rounded-lg flex justify-center;
    }
    .card-row {
        @apply bg-white w-full p-4 drop-shadow-md rounded-lg flex flex-row justify-between space-x-4 items-center;
    }
    .image-container {
        @apply bg-black rounded-lg;
    }
    .image-container img { opacity: 1; @apply rounded-lg }

    .image-container-vertical {
        @apply rounded-lg;
    }
    .image-container-vertical img { @apply rounded-lg }

    .icon-container {
        @apply bg-white w-24 h-20 rounded-lg shadow flex justify-center items-center;
    }
    .card-container {
        @apply w-full py-4 rounded-lg flex flex-col;
    }
    .card-footer {
        @apply w-full pt-4 rounded-lg flex justify-between items-center;
    }

    .card-f {
        @apply bg-white w-full p-4 drop-shadow-md rounded-lg flex flex-col justify-center;
    }
    .image-container-f {
        @apply bg-gray-400 w-full h-44 rounded-lg;
    }

    .btn-primary {
        @apply bg-success-100 hover:bg-blue-700 text-white font-bold py-2 px-2 rounded-l transition duration-700;
        z-index: 99;
        position: fixed;
        right: -80px;
        top: 120px;
    }
    .btn-secondary {
        @apply bg-blueteal-100 hover:bg-blue-700 text-white font-bold py-2 px-2 rounded-l transition duration-700;
        z-index: 99;
        position: fixed;
        right: -110px;
        top: 164px;
    }
}
.btn-secondary:hover {
    transform: translateX(-110px) !important;
}
.btn-primary:hover {
    transform: translateX(-80px) !important;
}
.st0 {
    @apply fill-secondaryLight;
}
.bg-slider {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    background-color: #0000003c;
    border-radius: 1.5rem;
}
.bg-recruitment-color {
    background-color: #eeedeb;
}
.bg-recruitment {
    background-image: url("https://gruposolana.com/corporativo/public/images/banners/recruitment.jpg");
    background-repeat: no-repeat;
    background-size: cover;
}

.line-separator-hyundai {
    padding-top: 20px;
    border-bottom: 4px solid #132c5f;
}

.line-separator-toyota {
    padding-top: 20px;
    border-bottom: 4px solid #ba2531;
}

.line-separator-jac {
    padding-top: 20px;
    border-bottom: 4px solid #000000;
}

.line-separator-suzuki {
    padding-top: 20px;
    border-bottom: 4px solid #1b3399;
}

.line-separator-suzuki {
    padding-top: 20px;
    border-bottom: 4px solid #c9272c;
}

.line-separator-vw {
    padding-top: 20px;
    border-bottom: 4px solid #173362;
}

p {
    @apply mb-4 text-gray-600 dark:text-gray-300;
}
#map {
    height: 400px;
}